import { defineStore } from 'pinia'
import classifyPoint from 'robust-point-in-polygon'
import { axiosInstance } from 'boot/axios'
import { Notify } from 'quasar'
import { i18n } from 'src/boot/i18n'
import { useSettingsStore } from './settings'
import { formatCurrency } from '../boot/helpers'

export const useCartStore = defineStore('cart', {
  state: () => ({
    store_id: null,
    name: '',
    group_order: false,
    order_token: null,
    participant_token: null,
    group_order_payment_type: 'split', // split or total
    group_order_admin: false,
    group_order_pay_extra: 0,
    phone: '',
    phone_country: null,
    address_id: null,
    address: '',
    address_line2: '',
    use_credit_card_id: null,
    geolocation: {
      lat: null,
      lng: null
    },
    delivery_area: {
      id: 0,
      fee_type: 'fixed',
      transport_price: 0,
      min_order_value: 0,
      min_order_free_transport: 0,
      fees: []
    },
    transport_price: 0,
    products: [],
    informations: '',
    accept_promotion: false,
    tos: false,
    age_restriction: false,
    contactless_delivery: false,
    cutlery: false,
    payment_method: null,
    voucher: null,
    delivery_type: 'pickup',
    pickup_type: null,
    only_for_later: false,
    for_later: false,
    later_day: null,
    later_time: null,
    loyalty_points: 0,
    table_id: null,
    table_number: null,
    stripe_client_secret: null,
    traffic_source: null
  }),
  getters: {
    minimumOrderValue(state) {
      if (state.delivery_type === 'delivery') {
        if (state.delivery_area.fee_type === 'range') {
          return Number(state.delivery_area.fees[0].from_value)
        } else {
          return Number(state.delivery_area.min_order_value)
        }
      }
      return 0
    },
    ageCheckBoxVisibility(state) {
      return state.products.some(product => product.age_restriction)
    },
    subTotalOrder(state) {
      let total = 0
      state.products.forEach(product => {
        total += product.quantity * product.price
        if (state.delivery_type !== 'table' && product.pack_price !== null) {
          total += product.quantity * product.pack_price
        }
        product.options.forEach(option => {
          total += product.quantity * option.price * option.quantity
        })
      })
      if (total < 0) {
        total = 0
      }
      return Math.round(total * 100) / 100
    },
    totalProducts(state) {
      let total = 0
      state.products.forEach(product => {
        total += product.quantity * product.price

        product.options.forEach(option => {
          total += product.quantity * option.price * option.quantity
        })
      })

      return total
    },
    myTotalOrder(state) {
      let total = this.subTotalOrder
      total += this.transportPrice
      if (state.voucher !== null) {
        total -= state.voucher.value_currency
      }
      if (state.loyalty_points !== null) {
        total -= Number(state.loyalty_points)
      }
      if (total < 0) {
        total = 0
      }
      if (state.group_order) {
        total += state.group_order_pay_extra
      }
      return Math.round(total * 100) / 100
    },
    totalOrder(state) {
      let total = this.subTotalOrder
      total += this.transportPrice
      if (state.voucher !== null) {
        total -= state.voucher.value_currency
      }
      if (state.loyalty_points !== null) {
        total -= Number(state.loyalty_points)
      }

      if (state.group_order) {
        total += state.group_order_pay_extra
      }
      if (total < 0) {
        total = 0
      }
      return Math.round(total * 100) / 100
    },
    totalOrderWithoutDelivery(state) {
      let total = this.totalOrder
      total -= this.transportPrice
      if (state.voucher !== null) {
        total -= state.voucher.value_currency
      }
      return total
    },
    transportPrice(state) {
      if (
        state.delivery_type !== 'delivery' ||
        (state.group_order && state.group_order_payment_type === 'total' && state.group_order_admin === false)
      ) {
        return 0
      }

      if (state.products.length === 0 && state.group_order_pay_extra === 0) {
        return 0
      }

      let total = this.subTotalOrder
      if (state.group_order) {
        total += state.group_order_pay_extra
      }
      if (state.voucher !== null) {
        total -= state.voucher.value_currency
      }
      if (state.delivery_area.fee_type === 'range') {
        let foundFee = state.delivery_area.fees.find(item => total >= item.from_value && total <= item.to_value)
        if (foundFee) {
          return Number(foundFee.fee)
        } else if (total < state.delivery_area.fees[0].from_value) {
          return Number(state.delivery_area.fees[0].fee)
        }
        return 0
      }
      if (state.delivery_area.min_order_free_transport === 0 || state.delivery_area.min_order_free_transport > total) {
        return Number(state.delivery_area.transport_price)
      }
      return 0
    },
    packPrice(state) {
      let total = 0
      state.products.forEach(product => {
        if (state.delivery_type !== 'table' && product.pack_price !== null) {
          total += product.quantity * product.pack_price
        }
      })
      return Math.round(total * 100) / 100
    }
  },
  actions: {
    getFromAPI(token) {
      if (token === '' || token === null) {
        return Promise.resolve(null)
      }
      return axiosInstance.get(`public/carts?token=${token}`).then(response => {
        return response.data.json_object
      })
    },
    updateStoreId(storeId) {
      this.store_id = storeId
    },
    updateDeliveryType(deliveryType) {
      this.delivery_type = deliveryType
    },
    setTrafficSource(trafficSource) {
      this.traffic_source = trafficSource
    },
    addProduct(product) {
      product.locked = false
      if (this.participant_token === null) {
        product.locked = false
        if (product !== null && typeof product === 'object') {
          this.products.push(product)
          this.updateVoucher()
        }
        return Promise.resolve()
      } else {
        return axiosInstance
          .post(`public/group-orders/add-product`, {
            participant_token: this.participant_token,
            product
          })
          .then(({ data }) => {
            if (data.status === 'OK') {
              product.locked = false
              if (data.product !== null && typeof product === 'object') {
                this.products.push(data.product)
                this.updateVoucher()
              }
            } else {
              Notify.create({
                message: data.message,
                html: true,
                position: 'top',
                color: 'negative'
              })
              throw data
            }
          })
      }
    },
    updateVoucher() {
      let categories = null
      let voucherValue = 0
      if (this.voucher !== null) {
        if (this.voucher.value_type === 'percent') {
          let total = 0

          this.products.forEach(product => {
            if (Array.isArray(product.categories)) {
              categories = product.categories.map(item => item.id)
            } else {
              categories = []
            }
            if (
              (this.voucher.exclude_category_id === null ||
                categories.indexOf(this.voucher.exclude_category_id) === -1) &&
              (this.voucher.category_id === null || categories.indexOf(this.voucher.category_id) !== -1)
            ) {
              total += Number(product.quantity) * Number(product.price)
              if (process.env.BOOSTEAT_MODE !== 'table-ordering' && product.pack_price !== null) {
                total += Number(product.quantity) * Number(product.pack_price)
              }
              product.options.forEach(option => {
                total += Number(product.quantity) * Number(option.price) * Number(option.quantity)
              })
            }
          })
          voucherValue = (this.voucher.value / 100) * total
          this.voucher.value_currency = Math.round(voucherValue * 100) / 100
        } else if (this.voucher.value_type === 'currency') {
          voucherValue = this.voucher.value
          this.voucher.value_currency = this.voucher.value
        } else if (this.voucher.value_type === 'products') {
          let quantity = 0
          let available = this.voucher.no_of_free_products - this.voucher.already_used_products
          this.products.forEach(product => {
            if (available > 0 && this.voucher.free_products.indexOf(product.id) !== -1) {
              quantity = available >= product.quantity ? product.quantity : available
              voucherValue += quantity * product.price

              if (process.env.BOOSTEAT_MODE !== 'table-ordering' && product.pack_price !== null) {
                voucherValue += quantity * product.pack_price
              }
              available -= quantity
            }
          })
          this.voucher.value_currency = voucherValue
        } else if (this.voucher.value_type === 'modifier_options') {
          let quantity = 0
          let available = this.voucher.no_of_free_products - this.voucher.already_used_modifier_options
          this.products.forEach(product => {
            product.options.forEach(option => {
              if (available > 0 && this.voucher.free_modifier_options.indexOf(option.id) !== -1) {
                quantity = available >= option.quantity ? option.quantity : available
                voucherValue += quantity * option.price
                available -= quantity
              }
            })
          })
          this.voucher.value_currency = voucherValue
        } else if (this.voucher.value_type === 'categories') {
          let available = this.voucher.no_of_free_products - this.voucher.already_used_products
          const eligibleItems = []
          this.products.forEach(product => {
            if (Array.isArray(product.categories)) {
              categories = product.categories.map(item => item.id)
            } else {
              categories = []
            }

            if (
              available > 0 &&
              (this.voucher.exclude_category_id === null ||
                categories.indexOf(this.voucher.exclude_category_id) === -1) &&
              (this.voucher.category_id === null || categories.indexOf(this.voucher.category_id) !== -1)
            ) {
              if (categories.some(category => this.voucher.free_categories.includes(category))) {
                // The product is eligible for the voucher

                for (let i = 0; i < product.quantity; i++) {
                  eligibleItems.push({ price: product.price, packagePrice: product.pack_price || 0 })
                }
              }
            }
          })

          eligibleItems.sort((a, b) => b.price - a.price)

          // Calculates voucher value based on the most expensive no_of_free_products products and packaging
          const numEligibleItems = eligibleItems.length
          const numFreeItems = Math.min(available, numEligibleItems)
          for (let i = 0; i < numFreeItems; i++) {
            voucherValue += eligibleItems[i].price
            voucherValue += Number(eligibleItems[i].packagePrice)
          }

          this.voucher.value_currency = Number(voucherValue.toFixed(2))
        }

        if (voucherValue === 0) {
          this.voucher = null
        }
      }
    },
    removeProduct(index) {
      if (this.participant_token === null) {
        this.removeProductFromStore(index)
        return Promise.resolve()
      } else {
        return axiosInstance
          .post(`public/group-orders/delete-product`, {
            participant_token: this.participant_token,
            order_product_id: this.products[index].order_product_id
          })
          .then(({ data }) => {
            if (data.status === 'OK') {
              this.removeProductFromStore(index)
            } else {
              Notify.create({
                message: data.message,
                html: true,
                position: 'top',
                color: 'negative'
              })
              throw data
            }
          })
      }
    },
    removeProductFromStore(index) {
      this.products.splice(index, 1)
      if (this.products.length === 0) {
        this.updateOnlyForLater(false)
        this.saveVoucher(null)
      }
      this.updateVoucher()
    },
    clearOrder() {
      this.products = []
      this.informations = ''
      this.accept_promotion = false
      this.contactless_delivery = false
      this.cutlery = false
      this.tos = false
      this.voucher = null
      this.transport_price = 0
      this.pickup_type = null
      this.only_for_later = false
      this.loyalty_points = 0
      this.traffic_source = null
    },
    cleanGroupOrder() {
      this.group_order = false
      this.order_token = null
      this.participant_token = null
      this.group_order_payment_type = 'split'
      this.group_order_admin = false
      this.group_order_pay_extra = 0
      this.informations = ''
      this.pickup_type = null
      this.transport_price = 0
      this.voucher = null
      this.loyalty_points = 0
      this.products = []
    },
    logoutFromCart() {
      this.name = null
      this.phone = null
      this.address_id = null
      this.use_credit_card_id = null
    },
    saveAddressAndGeolocation(data) {
      this.address = data.address
      this.geolocation = data.geolocation
      this.delivery_type = data.delivery_type
    },
    saveNamePhone(data) {
      this.name = data.name
      this.phone = data.phone
      this.phone_country = data.phone_country
    },
    saveDeliveryType(data) {
      this.delivery_type = data.delivery_type
    },
    saveVoucher(data) {
      this.voucher = data
      this.updateVoucher()
    },
    removeVoucher() {
      this.voucher = null
      this.updateVoucher()
    },
    setPhoneCountry(data) {
      this.phone_country = data
    },
    setLoyaltyPoints(data) {
      this.loyalty_points = data
    },
    updateOnlyForLater(data) {
      this.only_for_later = data
    },
    updateQuantity(item) {
      if (this.products[item.productIndex].quantity > 1 || item.quantity > 0) {
        if (this.participant_token === null) {
          this.products[item.productIndex].quantity += item.quantity
          this.updateVoucher()
          return Promise.resolve()
        } else {
          return axiosInstance
            .post(`public/group-orders/edit-product`, {
              participant_token: this.participant_token,
              order_product_id: this.products[item.productIndex].order_product_id,
              update_quantity: item.quantity
            })
            .then(({ data }) => {
              if (data.status === 'OK') {
                this.products[item.productIndex].quantity += item.quantity
                this.updateVoucher()
              } else {
                Notify.create({
                  message: data.message,
                  html: true,
                  position: 'top',
                  color: 'negative'
                })
                throw data
              }
            })
        }
      } else {
        return Promise.resolve()
      }
    },
    saveDeliveryArea(area) {
      this.delivery_area = {
        id: Number(area.id),
        fee_type: area.fee_type,
        fees: area.fee_type === 'range' ? area.fees : [],
        transport_price: Number(area.transport_price),
        min_order_value: Number(area.min_order_value),
        min_order_free_transport: Number(area.min_order_free_transport)
      }
    },
    setDeliveryArea(deliveryAreas, geolocation) {
      let found = false
      deliveryAreas.forEach(area => {
        if (found === false) {
          const polygon = []
          const polygonArea = JSON.parse(area.polygon)
          if (Array.isArray(polygonArea[0])) {
            polygonArea[0].forEach(position => {
              polygon.push([position.lat, position.lng])
            })
          }
          if (classifyPoint(polygon, [geolocation.lat, geolocation.lng]) !== 1) {
            found = true
            this.saveDeliveryArea(area)
          }
        }
      })
    },
    storeChanged() {
      if (this.products.length > 0) {
        const settings = useSettingsStore()
        let productsPriceChanged = []
        axiosInstance
          .get(
            `public/products?product_ids=${this.products.map(item => item.id).join(',')}&store_id=${
              this.store_id
            }&with=sizes`
          )
          .then(({ data }) => {
            const productsMap = data.products.map(item => item.id)
            let mapIndex = null
            let correctPrice = null
            this.products.forEach(product => {
              mapIndex = productsMap.indexOf(product.id)
              correctPrice = Number(data.products[mapIndex].price)
              if (typeof product.selected_size !== 'undefined' && product.selected_size !== null) {
                let sizeIndex = product.sizes.findIndex(item => item.product_store_id === product.selected_size)
                let sizeName = product.sizes[sizeIndex].name
                let sizeArray = Object.values(data.products[mapIndex].sizes)
                let sizeMapIndex = sizeArray.findIndex(item => item.size === sizeName)
                product.sizes = sizeArray
                product.selected_size = sizeArray[sizeMapIndex].id
                correctPrice = Number(sizeArray[sizeMapIndex].price)
              }
              if (mapIndex !== -1 && product.price !== correctPrice) {
                productsPriceChanged.push(
                  `${product.name}: ${i18n.global.t('common.from')} ${formatCurrency(
                    product.price,
                    settings.localization.currency
                  )} ${i18n.global.t('common.to')} ${formatCurrency(correctPrice, settings.localization.currency)}`
                )
                product.price = correctPrice
              }
            })
            if (productsPriceChanged.length > 0) {
              Notify.create({
                message: i18n.global.t('cart.store-change-result-in-price-change', {
                  products: productsPriceChanged.join('<br/>- ')
                }),
                html: true,
                position: 'top',
                timeout: 10000 + productsPriceChanged.length * 1000,
                color: 'warning',
                closeBtn: 'X',
                textColor: 'black'
              })
            }
          })
      }
    }
  }
})
