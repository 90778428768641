export default {
  homepage: {
    'what-do-you-want-to-eat': 'Ko norite valgyti šiandien?',
    'choose-delivery-type-let-discover':
      'Pasirinkite pristatymo būdą ir atraskite visus produktus, kurie yra tik vieno paspaudimo atstumu.'
  },
  products: {
    unavailable: 'Nepasiekiamas'
  },
  axios: {
    403: 'Jūs neturite prieigos prie šios sekcijos. Pabandykite atsijungti ir vėl prisijungti.',
    524: 'Galima, kad užsakymas jau buvo įvykdytas. Patikrinkite tai ir, jei ne, bandykite dar kartą.',
    'has-unkown-error': 'Klaida apdorojant duomenis.',
    'no-internet': 'Prašome patikrinti interneto ryšį.',
    'unknown-error': 'Prašome susisiekti su mumis.',
    'message-not-sent': 'Žinutė nebuvo išsiųsta.'
  },
  'something-went-wrong': 'Klaida! Kažkas nepavyko.',
  product: {
    description: 'Aprašymas',
    allergens: 'Alergenai',
    'nutritional-facts': 'Mitybos faktai',
    ingredients: 'Ingredientai'
  },
  'payment-methods': {
    cash: 'Grynaisiais',
    'cash-pickup': 'Mokėjimas grynaisiais atsiimant',
    pos: 'Mokėjimas kortele POS sistemoje',
    'pos-pickup': 'Mokėjimas kortele POS sistemoje (atsiimant)',
    card: 'Mokėjimas kortele internetu',
    paypal: 'Mokėjimas per PayPal',
    'pay-with': 'Mokėkite su',
    by_organizer: 'Mokėjimą atliko organizatorius',
    words: {
      cash: 'PINIGAI',
      card: 'KORTELĖ'
    }
  },
  paymentStatus: {
    succeeded: 'Mokėjimas sėkmingai atliktas.',
    processing: 'Mokėjimas apdorojamas. Mes jus informuosime, kai gausime mokėjimą.',
    'requires-payment-method': 'Mokėjimas nepavyko. Prašome pabandyti kita mokėjimo būdą.',
    'error-checking':
      'Mokėjimo patikrinti nepavyko. Prašome patikrinti savo banko sąskaitą, ar pinigai buvo nuskaityti.'
  },
  validation: {
    'insert-full-name': 'Prašome įvesti visą vardą.'
  },
  delivery: 'Pristatymas',
  pickup: 'Atsiėmimas',
  categories: 'Kategorijos',
  'your-last-order': 'Jūsų paskutinė užsakymas',
  'recommended-products': 'Rekomenduojami produktai',
  'all-products': 'Visi produktai',
  'search-product': 'Ieškoti produkto...',
  'no-search-result': '0 rezultatų rasti: {search}',
  cookies: {
    welcome: 'Sveiki atvykę!',
    'info-message': 'Noriame pasiūlyti individualią patirtį',
    'info-message-2': 'Šis puslapis naudoja slapukus, siekiant pasiūlyti individualią patirtį',
    settings: 'Slapukų nustatymai',
    'i-agree': 'Sutinku',
    'settings-dialog': {
      title: 'Šis puslapis naudoja slapukus',
      info: 'Mes naudojame būtinus slapukus, kad platforma veiktų.<br>Taip pat norėtume nustatyti neprivalomus analizės slapukus, kurie mums padėtų optimizuoti svetainės funkcionalumą ir analizuoti bei tobulinti produktą.<br>Mes nenaudosime analizės slapukų, nebent juos aktyvuosite. Norėdami gauti daugiau informacijos apie naudojamus slapukus, skaitykite mūsų <a href="#" class="text-positive">Slapukų politiką</a>.'
    },
    needed: 'Būtini',
    performance: 'Našumas',
    statistically: 'Statistika',
    marketing: 'Marketingas'
  },
  contact: {
    title: 'Kontaktai'
  },
  reservations: {
    title: 'Rezervacijos',
    'over-phone': 'Rezervuoti telefonu',
    'send-success': 'Forma sėkmingai išsiųsta',
    'you-will-be-contacted': 'Jus susisieks mūsų atstovas kuo greičiau.'
  },
  account: {
    title: 'Mano paskyra',
    details: 'Paskyros detalės',
    benefits: 'Privalumai',
    'delete-account': 'Ištrinti paskyrą',
    'no-longer-access-to-account': 'Daugiau neturėsite prieigos prie savo privalumų ir specialių pasiūlymų.',
    'are-you-sure-delete-account': 'Ar tikrai norite ištrinti šią paskyrą?',
    'delete-account-lose-points':
      'Prarasite <b>{points} {currency}</b> bonuso taškų ir <b>nebegalėsite naudoti kuponų</b>.',
    'delete-account-reason': 'Priežastis, kodėl norite ištrinti paskyrą',
    'delete-delivery-address': 'Ištrinti pristatymo adresą',
    'are-you-sure-delete-delivery-address': 'Ar tikrai norite ištrinti adresą: {name}?',
    'delete-credit-card': 'Ištrinti kortelę',
    'are-you-sure-delete-credit-card': 'Ar tikrai norite ištrinti kortelę: {last4}?',
    'add-new-card': 'Naują kortelę galite pridėti tik užsakymo metu.',
    orders: 'Užsakymai',
    'current-orders': 'Dabartiniai užsakymai',
    'past-orders': 'Ankstesni užsakymai',
    'change-password': 'Keisti slaptažodį'
  },
  menu: 'Meniu',
  'about-us': {
    title: 'Apie mus'
  },
  jobs: {
    title: 'Karjera',
    'choose-the-right-job-for-you': 'Pasirinkite tinkamą darbą',
    'apply-for-job': 'Kandidatuoti į šią poziciją',
    'upload-cv': 'Įkelti CV'
  },
  events: {
    title: 'Renginiai',
    'free-entrance': 'Įėjimas nemokamas',
    'current-events': 'Dabartiniai renginiai',
    'past-events': 'Praėję renginiai',
    'see-location': 'Žiūrėti vietą',
    'send-reservation': 'Siųsti rezervaciją į renginį',
    'no-events': 'Šiuo metu renginių nėra',
    'follow-us': 'Sekite mus socialinėse medijose, norėdami sužinoti, kada organizuosime kitą renginį.'
  },
  legal: {
    title: 'Teisinis pagrindas',
    'terms-and-conditions': 'Sąlygos ir nuostatos',
    'cookie-policy': 'Slapukų politika',
    'privacy-policy': 'Privatumo politika'
  },
  filters: {
    'select-allergens': 'Pasirinkite alergenus, kurių turėtume vengti',
    'info-allergens': 'Visos kategorijos apima ir išvestinius produktus',
    allergens: {
      1: 'Glitimas',
      2: 'Pieno produktai',
      3: 'Vėžiagyviai',
      4: 'Kiaušiniai',
      5: 'Žuvis',
      6: 'Žemės riešutai',
      7: 'Sojos pupelės',
      8: 'Riešutai',
      9: 'Selerai',
      10: 'Garstyčios',
      11: 'Sezamo sėklos',
      12: 'Sieros dioksidas',
      13: 'Lupinos',
      14: 'Moliuskai'
    },
    reset: 'Atstatyti filtrus'
  },
  geolocation: {
    'choose-other-pickup': 'Pasirinkite kitą atsiėmimo vietą',
    'choose-other-delivery': 'Pasirinkite kitą pristatymo vietą',
    'see-location-details': 'Peržiūrėti vietos detales',
    'warning-delivery': 'Dėmesio, kai kurie produktai ir nuolaidos nėra prieinami pristatymui.',
    'street-number': 'Gatvė, numeris',
    'apartment-floor': 'Aukštas, butas, durų kodas',
    'location-name': 'Vietos pavadinimas, pvz.: Namuose (neprivaloma)',
    'special-informations': 'Specialūs pageidavimai (neprivaloma)',
    'no-address': 'Turite įvesti adresą.',
    'no-delivery-to-this-location': 'Deja, mes nepristatome į šį adresą.',
    'leave-email-notify-delivery-area':
      'Galite įvesti el. pašto adresą ir būsite informuotas, kai pristatymas bus prieinamas jūsų rajone.',
    'no-delivery-to-this-location-has-pickup':
      'Deja, mes nepristatome į šį adresą. Galite pasirinkti užsisakyti ir atsiimti iš mūsų vietos.',
    'address-saved-success': 'Adresas sėkmingai išsaugotas.',
    'location-saved-success': 'Vieta sėkmingai išsaugota.',
    'add-address': 'Pridėti naują adresą',
    'edit-address': 'Redaguoti adresą',
    'save-address': 'Išsaugoti adresą',
    'select-address-on-the-map': 'Pasirinkite savo adresą žemėlapyje',
    'no-results':
      'Adresas nerastas. Pabandykite ieškoti miesto ar kito svarbaus taško šalia jūsų vietos ir tada galite perkelti žymeklį tiesiai į savo vietą.'
  },
  'order-product': {
    'special-instructions': 'Specialios instrukcijos',
    'instructions-extra-charge': '',
    'add-product-to-order': 'Pridėti į krepšelį',
    maxim: 'Maksimalus',
    options: 'parinktys',
    'product-added-success': 'Produktas sėkmingai pridėtas.',
    'product-delete-success': 'Produktas sėkmingai pašalintas.',
    'max-options': 'Didžiausias galimas pasirinktų parinkčių skaičius yra {options}.',
    'max-quantity': 'Didžiausias galimas užsakymo kiekis yra {quantity}.',
    'min-options-notification': 'Minimalus {name} parinkčių skaičius yra {options}.',
    'min-quantity-notification': 'Minimalus {name} užsakymo kiekis yra {quantity}.',
    'choose-option': 'Pasirinkite parinktį',
    'already-added-order-now':
      'Jūsų krepšelyje yra produktų, skirtų pristatyti šiandien. Šiandienos pristatymo užsakymas negali būti derinamas su numatytu pristatymu.'
  },
  cart: {
    'no-products': 'Šiuo metu jūsų krepšelyje nėra jokių prekių',
    'please-add-product': 'Prašome pridėti prekių į krepšelį, kad galėtumėte užsisakyti.',
    'what-do-you-want-to-eat': 'Ko norėtumėte valgyti šiandien?',
    'group-order': {
      or: '- arba -',
      'initiate-group-order': 'Inicijuoti <br> grupės užsakymą',
      title: 'Grupės užsakymas',
      'payment-method': 'Kaip norite atlikti mokėjimą?',
      'info-link':
        'Siųskite šią nuorodą žmonėms, kuriuos norite pakviesti į grupės užsakymą, visada galėsite ištrinti dalyvius.',
      'copy-link': 'Kopijuoti užsakymo nuorodą',
      'share-link': 'Dalintis užsakymo nuoroda',
      'open-order': 'Atidaryti grupės užsakymą',
      'split-order': 'Padalinti užsakymą',
      'split-order-info': 'Kiekvienas moka savo kortele',
      'integral-order': 'Aš apmokėsiu visą užsakymą',
      'integral-order-info': 'Mokėti kortele arba grynaisiais atsiimant',
      'delete-order': 'Ištrinti grupės užsakymą',
      'info-group-order':
        'Galite padaryti grupės užsakymą neturėdami produktų savo krepšelyje. Tai galite padaryti iš "Dalyvių" skyriaus.',
      'join-group-order-created-by': 'Prisijunkite prie {name} sukurtos grupės užsakymo.',
      'join-group-order': 'Prisijunkite prie grupės užsakymo',
      'organizer-pay-the-order': 'Organizatorius apmoka užsakymą',
      'organizer-pay-the-order-info': 'Organizatorius apmoka užsakymą kortele arba grynaisiais atsiimant.',
      'info-group-order-required-fields': 'Laukai, pažymėti <span style="color:#66BF81">*</span> yra privalomi.',
      'join-group-order-login-info': 'Norėdami dalyvauti grupės užsakyme, pasirinkite vieną iš autentifikacijos būdų.',
      'join-group-order-withou-login': 'Dalyvauti grupės užsakyme be prisijungimo:',
      'join-group-order-without-login-info': 'Norėdami dalyvauti grupės užsakyme, neprisijungę, įveskite savo vardą.',
      'join-success': 'Jūs sėkmingai prisijungėte prie grupės užsakymo.',
      'link-copied': 'Užsakymo nuoroda nukopijuota:',
      'wait-for-organizer-to-place-order': 'Laukiama, kol organizatorius užbaigs užsakymą',
      'thank-you-for-your-payment': 'Ačiū už jūsų mokėjimą',
      'thank-you-for-confirming-the-order': 'Ačiū už užsakymo patvirtinimą',
      'are-you-sure-delete-order': 'Ar tikrai norite ištrinti šį grupės užsakymą?',
      'giveup-on-order': 'Atsisakyti grupės užsakymo',
      'are-you-sure-giveup-order': 'Ar tikrai norite atsisakyti šio grupės užsakymo?',
      'not-allowed-because-already-started': 'Šis veiksmas negalimas, nes grupės užsakymas jau vyksta.',
      'payment-made': 'Mokėjimas atliktas',
      'panding-payment': 'Mokėjimas atliekamas &nbsp',
      'payment-by-organizer': 'Mokėjimas atliktas organizatoriaus',
      participants: 'Dalyviai',
      'delete-participant': 'Ištrinti dalyvį',
      'are-you-sure-delete-participant': 'Ar tikrai norite ištrinti šį dalyvį iš grupės užsakymo?',
      'my-total': 'Mano suma',
      'paid-total': 'Sumokėta suma',
      'total-group-order': 'Grupės užsakymo suma',
      'pay-for-participant': 'Sumokėti už dalyvį',
      'all-participants-must-confirm':
        'Visi grupės užsakymo dalyviai turi patvirtinti savo užsakymą, kad jis būtų užbaigtas. Galite pasirinkti sumokėti už kitus dalyvius arba juos pašalinti iš grupės užsakymo.',
      'order-confirmed': 'Užsakymas patvirtintas',
      'pending-order-confirmation': 'Laukiama užsakymo patvirtinimo &nbsp'
    },
    'money-will-be-refunded': 'Sumokėta suma bus grąžinta į jūsų sąskaitą artimiausiomis dienomis.',
    'delete-product': 'Pašalinti produktą',
    'are-you-sure-remove-product': 'Ar tikrai norite pašalinti produktą <b>{productName}</b> iš užsakymo?',
    'loyalty-at-checkout': 'Galimybė <b>taikyti bonusinius taškus ir kuponus</b> yra galutinio užsakymo etape.',
    'loyalty-validation-error': 'Įvestų taškų skaičius yra neteisingas.',
    'delivery-tax': 'Pristatymo mokestis',
    'packaging-tax': 'Pakuotės mokestis',
    total: 'Iš viso',
    'total-with-benefits': 'Iš viso su privalumais:',
    'total-products': 'Iš viso produktų',
    'minimum-order-is': 'Minimalus užsakymas yra {value}. Pristatymas neskaičiuojamas.',
    'minimum-order-voucher-is': 'Minimalus užsakymas norint naudoti šį kuponą yra {value}.',
    'place-a-new-order': 'Padaryti naują užsakymą',
    checkout: {
      'finish-order': 'Užbaigti užsakymą',
      'do-you-want-cutlery': 'Ar reikia Įrankių?',
      'when-to-receive-order': 'Kada norėtumėte gauti užsakymą',
      'have-a-promotional-code': 'Ar turite pašalpos kodą (kuponą)?',
      'only-one-voucher-per-order': 'Kuponai negali būti kumuliuojami. Galite naudoti tik vieną kuponą per užsakymą.',
      'loyalty-explained':
        "Maksimalus taškų skaičius / užsakymas yra {points}. <br/> Bonusiniai taškai kaupiami užsakius užsakymą. <br/> 1 taškas = {one_currency}, sužinokite daugiau informacijos skyriuje <a href='/account/benefits'>Privalumai</a> savo profilyje.",
      'save-card': 'Išsaugokite kortelę savo paskyroje ateities užsakymams',
      'use-other-card': 'Naudoti kitą kortelę',
      'age-restriction':
        'Patvirtinu, kad esu pilnametis, kad galėčiau įsigyti šiuos produktus, būdamas {age} metų ar vyresnis'
    },
    'store-change-result-in-price-change': 'Prekių kainos pasikeitė pakeitus vietovę: <br/>- {products}'
  },
  'order-success': {
    title: 'Dėkojame už užsakymą.',
    body: 'Produktai bus pristatyti kuo greičiau.',
    'body-pickup': 'Produktai bus pradėti gaminti kuo greičiau.',
    'wait-time-delivery': 'Užsakymas atvyks per <span style="white-space:nowrap">~{minutes} minutes</span>.',
    'wait-time-pickup': 'Užsakymas bus paruoštas per <span style="white-space:nowrap">~{minutes} minutes</span>.',
    'later-body-delivery': 'Jūsų užsakymas bus pristatytas jūsų nurodytu adresu {time}.',
    'later-body-pickup': 'Jūsų užsakymą galite atsiimti {time} iš vietovės {store_name}.',
    'see-order-status-on-orders-page': 'Sekite užsakymo būseną skyriuje <a href="/account/orders">Užsakymai</a>',
    'order-brought-to-you':
      'Šis užsakymas jums atneš <span class="color-green"><b>{points} bonusų taškus</b></span>, daugiau informacijos galite rasti skyriuje <a href="/account/benefits">Privalumai</a>',
    'order-will-bring-you':
      'Šis užsakymas jums suteiks <span class="color-green"><b>{points} bonusų taškus</b></span>, kuriuos galite paversti nuolaidomis kitam užsakymui, <b>1 taškas = {one_currency}</b>.'
  },
  auth: {
    'password-strength': 'Slaptažodžio stiprumas',
    'password-strength-weak': 'Silpnas',
    'password-strength-medium': 'Vidutinis',
    'password-strength-good': 'Geras',
    'password-strength-strong': 'Stiprus',
    'password-strength-very-strong': 'Labai stiprus',
    'enter-account': 'Prisijungti prie paskyros',
    'login-for-ordering': 'Norėdami padaryti užsakymą, pasirinkite vieną iš autentifikacijos būdų.',
    'reset-password': 'Atkurti slaptažodį',
    'reset-password-info': 'Prašome įvesti el. pašto adresą, norėdami atkurti slaptažodį',
    'new-password': 'Nustatyti naują slaptažodį',
    'forgot-password': 'Pamiršote slaptažodį?',
    'validate-phone-number': 'Patvirtinkite telefono numerį',
    'validate-phone-enter-code': 'Įveskite SMS žinute gautą 4 skaitmenų kodą:',
    'validate-phone-number-resend-text': 'Pakartotinai siųsti SMS',
    register: 'Sukurti paskyrą',
    'login-with-google-unavailable': 'Prisijungimas per Google šiuo metu negalimas.',
    'order-wihout-account': 'Užsisakyti be paskyros',
    'place-an-order': 'Padaryti užsakymą',
    'terms-and-conditions': 'Naudojimosi sąlygos ir taisyklės',
    'continue-accept-tos': 'Tęsdami, jūs sutinkate su naudojimosi sąlygomis ir taisyklėmis.',
    'accept-promotion': 'Sutinku gauti individualias akcijas.',
    'i-accept-promotion': 'Noriu gauti asmeninius pasiūlymus.',
    'i-agree-with': 'Sutinku su',
    tos: 'naudojimosi sąlygomis ir taisyklėmis',
    logout: 'Atsijungti',
    'logout-success': 'Sėkmingai atsijungėte nuo paskyros.',
    'are-you-sure-logout': 'Ar tikrai norite atsijungti?'
  },
  common: {
    'email-saved-success': 'El. pašto adresas sėkmingai išsaugotas.',
    'invalid-email': 'Neteisingas el. pašto adresas',
    'delivery-method': 'Pristatymo būdas',
    'delivery-address': 'Pristatymo adresas',
    'pickup-address': 'Atsiėmimo adresas',
    'payment-method': 'Mokėjimo būdas',
    'contact-informations': 'Kontaktinė informacija',
    yes: 'Taip',
    no: 'Ne',
    order: 'Užsakymas',
    'my-order': 'Mano užsakymas',
    'payment-status': 'Mokėjimo būsena',
    'want-more': 'Norite dar ko?',
    'add-to-order': 'Pridėti prie užsakymo',
    'select-language': 'Pasirinkite kalbą',
    search: 'Ieškoti...',
    from: 'nuo',
    to: 'iki',
    delete: 'Ištrinti'
  },
  loyalty: {
    discounts: 'Nuolaidos',
    'this-order-brings-you':
      'Šis užsakymas jums suteiks <span class="color-green"><b>{points} bonusų taškus</b></span>, kuriuos galite paversti nuolaidomis kitam užsakymui, <b>1 taškas = {one_currency}</b>.',
    'create-account-for-loyalty':
      'Sukurkite paskyrą, kad galėtumėte gauti bonusų taškus, kuponus ir specialius pasiūlymus.',
    'available-bonus-points': 'Turimi bonusų taškai',
    'congrats-your-total-points': '<b>Sveikiname!</b> Bendras bonusų taškų kiekis yra:',
    vouchers: 'Kuponai',
    'available-vouchers': 'Turimi kuponai',
    'no-available-vouchers': 'Neturite galiojančių kuponų.',
    'vouchers-info':
      'Kuponai negali būti sujungti. Vienam užsakymui galite naudoti tik vieną kuponą.<br/>Kontaktai negali būti perleisti kitiems naudotojams.<br/>Kuponus galite gauti po specialių akcijų.<br/>Kontaktai galioja.',
    'bonus-points-info':
      'Bonusų taškai kaupiami už užsakymus.<br/><b>1 taškas = {one_currency}</b>, galite naudoti bonusų taškus norėdami gauti nuolaidų už užsakymus.<br/>Bonusų taškai neturi galiojimo laikotarpio.<br/>Bonusų taškus galima naudoti kartu su kitais privalumais (pvz., kuponais, akcijomis)',
    'no-bonus-points': 'Šiuo metu neturite bonusų taškų'
  },
  'table-ordering': {
    title: 'Užsakymas prie stalo',
    'table-number': 'Stalas Nr. {number}',
    'send-order-to-waiter': 'Siųsti užsakymą padavėjui',
    'any-time-you-can-ask-for-waiter': 'Bet kuriuo metu galite paprašyti padavėjo pagalbos.',
    'what-qr-can-do-info-ordering':
      'Nuskenuodami QR kodą galite peržiūrėti meniu, padaryti užsakymą ir galiausiai jį nuskenuoti mokėdami už sąskaitą.',
    'what-qr-can-do-infocall-waiter':
      'Per QR kodą galite peržiūrėti meniu, paskambinti padavėjui ir pabaigoje paprašyti sąskaitos.',
    'order-received': 'Užsakymas priimtas',
    bill: 'Sąskaita',
    'call-waiter': 'Paskambink padavėjui',
    'ask-for-bill': 'Paprašykite sąskaitos'
  },
  buttons: {
    confirm: 'Patvirtinti',
    continue: 'Tęsti',
    save: 'Išsaugoti',
    'save-address': 'Išsaugoti adresą',
    send: 'Siųsti',
    'place-order': 'Pateikti užsakymą',
    cancel: 'Atšaukti',
    'continue-shopping': 'Tęsti apsipirkimą',
    validate: 'Patvirtinti',
    use: 'Naudoti',
    close: 'Uždaryti',
    'pay-with-cc': 'Mokėti internetu kortele',
    'change-payment': 'Pakeisti mokėjimo būdą',
    'back-to-menu': 'Grįžti į meniu'
  },
  fields: {
    name: 'Vardas',
    'your-name': 'Jūsų vardas',
    email: 'El. paštas',
    'email-address': 'El. pašto adresas',
    phone: 'Telefonas',
    password: 'Slaptažodis',
    'old-password': 'Senas slaptažodis',
    'new-password': 'Naujas slaptažodis',
    date: 'Data',
    number: 'Numeris',
    total: 'Viso',
    'pickup-by-car': 'Atsiėmimas automobiliu',
    'pickup-counter': 'Atsiėmimas iš registratūros',
    'pickup-now': 'Atsiimti per {minutes} min.',
    'pickup-later': 'Atsiimti vėliau',
    'delivery-now': 'Pristatyti kuo greičiau (~{minutes} min.)',
    'delivery-later': 'Pristatyti vėliau',
    size: 'Dydis',
    voucher: 'Kuponas',
    points: 'taškai',
    day: 'Diena',
    hour: 'Valanda',
    'bonus-points': 'Bonus taškai',
    'your-message': 'Jūsų žinutė',
    'no-of-persons': 'Asmenų skaičius',
    event: 'Įvykis'
  }
}
