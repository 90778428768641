export default {
  homepage: {
    'what-do-you-want-to-eat': 'De ce ai poftă azi?',
    'choose-delivery-type-let-discover':
      'Alege metoda de livrare și hai să descoperim toate produsele care sunt doar la un click distanță.'
  },
  products: {
    unavailable: 'Indisponibil'
  },
  axios: {
    403: 'Nu aveți acces la această secțiune. Încercați să ieșiți din cont și să intrați înapoi.',
    524: 'Este posibil ca comanda să se fi executat. Verificați acest lucru și daca nu, încercați din nou.',
    'has-unkown-error': 'Eroare la procesarea datelor.',
    'no-internet': 'Va rugam sa verificati conexiunea la internet.',
    'unknown-error': 'Va rugam sa ne contactati.',
    'message-not-sent': 'Mesajul nu a fost trimis.'
  },
  'something-went-wrong': 'Eroare! Ceva n-a mers bine.',
  product: {
    description: 'Descriere',
    allergens: 'Alergeni',
    'nutritional-facts': 'Valori nutriționale',
    ingredients: 'Ingrediente'
  },
  'payment-methods': {
    cash: 'Plata numerar',
    'cash-pickup': 'Plata numerar la ridicare',
    pos: 'Plata cu cardul la POS',
    'pos-pickup': 'Plata cu cardul la POS (la ridicare)',
    card: 'Plata online cu cardul',
    paypal: 'Plata online cu PayPal',
    'pay-with': 'Plătește cu',
    by_organizer: 'Plată efectuată de organizator',
    words: {
      cash: 'NUMERAR',
      card: 'CARD'
    }
  },
  paymentStatus: {
    succeeded: 'Plata a fost realizată cu succes.',
    processing: 'Plata se procesează. Vă vom informa când se primește plata.',
    'requires-payment-method': 'Plată eșuată. Vă rugăm să încercați o altă metodă de plată.',
    'error-checking':
      'Plata nu a putut fi verificată.\nVă rugăm să verificați contul bancar, dacă banii au fost retrași.'
  },
  validation: {
    'insert-full-name': 'Te rugam sa introduci numele complet.'
  },
  delivery: 'Livrare',
  pickup: 'Ridicare',
  categories: 'Categorii',
  'your-last-order': 'Ultima ta comandă',
  'recommended-products': 'Produse recomandate',
  'all-products': 'Toate produsele',
  'search-product': 'Caută produs...',
  'no-search-result': '0 rezultate pentru: {search}',
  cookies: {
    welcome: 'Bine ai venit!',
    'info-message': 'Vrem să-ți oferim o experiență personalizată',
    'info-message-2': 'Acest site folosește cookie pentru a-ți oferi o experiență personalizată',
    settings: 'Setări cookie',
    'i-agree': 'Sunt de acord',
    'settings-dialog': {
      title: 'Acest site utilizează cookie-uri',
      info: 'Folosim cookie-uri necesare pentru ca platforma să funcționeze.<br>De asemenea, am dori să setăm cookie-uri de analiză opționale pentru a putea optimiza funcționalitatea site-ului și pentru a analiza și îmbunătăți produsul.<br>Nu vom seta cookie-uri de analiză decât dacă le activezi. Pentru informații suplimentare despre cookie-urile pe care le folosim, consultă <a href="#" class="text-positive">Politica Cookies</a>.'
    },
    needed: 'Necesare',
    performance: 'Performanta',
    statistically: 'Statistici',
    marketing: 'Marketing'
  },
  contact: {
    title: 'Contact'
  },
  reservations: {
    title: 'Rezervări',
    'over-phone': 'Pentru rezervări telefonice',
    'send-success': 'Formularul a fost trimis cu success',
    'you-will-be-contacted': 'Vei fi contactat de un reprezentat în cel mai scurt timp.'
  },
  account: {
    title: 'Contul meu',
    details: 'Detaliile contului',
    benefits: 'Beneficii',
    'delete-account': 'Șterge contul',
    'no-longer-access-to-account': 'Nu vei mai avea acces la beneficiile tale, și ofertele speciale.',
    'are-you-sure-delete-account': 'Ești sigur că dorești să ștergi acest cont?',
    'delete-account-lose-points':
      'Vei pierde <b>{points} {currency}</b> în puncte bonus, și <b>nu vei mai putea folosi voucherele</b>.',
    'delete-account-reason': 'Motivul pentru care dorești să ștergi contul',
    'delete-delivery-address': 'Șterge adresa de livrare',
    'are-you-sure-delete-delivery-address': 'Ești sigur că dorești să ștergi adresa: {name}?',
    'delete-credit-card': 'Șterge cardul',
    'are-you-sure-delete-credit-card': 'Ești sigur că dorești să ștergi cardul: {last4}?',
    'add-new-card': 'Poți adăuga un card nou, doar în momentul în care plasezi o nouă comandă.',
    orders: 'Comenzi',
    'current-orders': 'Comenzi curente',
    'past-orders': 'Istoric comenzi',
    'change-password': 'Schimbă parola'
  },
  menu: 'Meniu',
  'about-us': {
    title: 'Despre noi'
  },
  jobs: {
    title: 'Cariere',
    'choose-the-right-job-for-you': 'Alege jobul potrivit pentru tine',
    'apply-for-job': 'Aplică pentru această poziție',
    'upload-cv': 'Încarcă CV'
  },
  events: {
    title: 'Evenimente',
    'free-entrance': 'Intrare gratuită',
    'current-events': 'Evenimente curente',
    'past-events': 'Evenimente din trecut',
    'see-location': 'Vezi locația',
    'send-reservation': 'Trimite o rezervare pentru eveniment',
    'no-events': 'Momentan nu există nici un eveniment',
    'follow-us':
      'Te rugăm să ne urmărești pe una din rețelele de socializare pentru a afla când organizăm următorul eveniment.'
  },
  legal: {
    title: 'Cadru legal',
    'terms-and-conditions': 'Termeni și condiții',
    'cookie-policy': 'Politica cookies',
    'privacy-policy': 'Politica de confidențialitate'
  },
  filters: {
    'select-allergens': 'Selectează alergenii pe care să îi evităm',
    'info-allergens': 'Toate categoriile includ și produse derivate',
    allergens: {
      1: 'Gluten',
      2: 'Lactate',
      3: 'Crustacee',
      4: 'Ouă',
      5: 'Pește',
      6: 'Arahide',
      7: 'Soia',
      8: 'Nuci',
      9: 'Țelină',
      10: 'Muștar',
      11: 'Semințe de susan',
      12: 'Dioxid de sulf',
      13: 'Lupin',
      14: 'Moluște'
    },
    reset: 'Resetează filtre'
  },
  geolocation: {
    'choose-other-pickup': 'Alege o altă adresă de ridicare',
    'choose-other-delivery': 'Alege o altă adresă de livrare',
    'see-location-details': 'Vezi detaliile locațiilor',
    'warning-delivery': 'Atenție, unele produse și reduceri nu sunt disponibile pentru comanda cu livrare.',
    'street-number': 'Stradă, număr',
    'apartment-floor': 'Etaj, apartament, interfon',
    'location-name': 'Numele locației ex: Acasă (opțional)',
    'special-informations': 'Instrucțiuni speciale (opțional)',
    'no-address': 'Trebuie să introduceți o adresă.',
    'no-delivery-to-this-location': 'Din păcate nu livrăm la această adresă.',
    'leave-email-notify-delivery-area':
      'Poți introduce adresa de e-mail și vei fi notificat când livrarea este disponibilă în zona ta.',
    'no-delivery-to-this-location-has-pickup':
      'Din păcate nu livrăm la această adresă. Poți să alegi să comanzi și să ridici din locația noastră.',
    'address-saved-success': 'Adresa a fost salvată cu succes.',
    'location-saved-success': 'Locația a fost salvată cu succes.',
    'add-address': 'Adaugă adresa nouă',
    'edit-address': 'Editează adresa',
    'save-address': 'Salvează adresa',
    'select-address-on-the-map': 'Localizează adresa ta pe hartă',
    'no-results':
      'Nu s-a găsit adresa. Încercați să căutați orașul sau alt punct important de langa locația dvs. și apoi puteți muta marker-ul exact la locația dvs.'
  },
  'order-product': {
    'special-instructions': 'Instrucțiuni speciale',
    'instructions-extra-charge': '',
    'add-product-to-order': 'Adaugă în coș',
    maxim: 'Maxim',
    options: 'opțiuni',
    'product-added-success': 'Produsul a fost adăugat cu succes.',
    'product-delete-success': 'Produsul a fost șters cu succes.',
    'max-options': 'Numarul maxim de optiuni ce pot fi selectate este {options}.',
    'max-quantity': 'Cantitatea maximă care poate fi comandată este de {quantity}.',
    'min-options-notification': 'Numărul minim de opțiuni ce trebuie selectate pentru {name} este {options}.',
    'min-quantity-notification': 'Cantitatea minimă care poate fi comandată pentru {name} este de {quantity}.',
    'choose-option': 'Alege opțiunea',
    'already-added-order-now':
      'Aveti in cos produse cu livrare astazi. Comanda cu livrare astazi nu poate fi combinata cu o comanda cu livrare la o data programata.'
  },
  cart: {
    'no-products': 'Momentan nu ai nici un produs în coș',
    'please-add-product': 'Te rugăm să adaugi produse în coș pentru a putea plasa o comandă.',
    'what-do-you-want-to-eat': 'De ce ai poftă azi?',
    'group-order': {
      or: '- sau -',
      'initiate-group-order': 'Inițiază o <br> comandă de grup',
      title: 'Comanda de grup',
      'payment-method': 'Cum dorești să se facă plata?',
      'info-link':
        'Trimite acest link persoanelor pe care dorești sa le inviți în comanda de grup, poți oricând să ștergi participanți.',
      'copy-link': 'Copiază link comandă',
      'share-link': 'Distribuie link comandă',
      'open-order': 'Deschide comanda de grup',
      'split-order': 'Împărțiți nota',
      'split-order-info': 'Plătește fiecare cu cardul personal',
      'integral-order': 'Plătesc eu integral',
      'integral-order-info': 'Cu cardul sau cash la livrare',
      'delete-order': 'Șterge comanda de grup',
      'info-group-order':
        'Poți plasa o comandă de grup fără să ai produse in coș. Fă acestă acțiune din secțiune “Participanți”.',
      'join-group-order-created-by': 'Alăturăte comenzii de grup creată de {name}.',
      'join-group-order': 'Alăturăte comenzii de grup',
      'organizer-pay-the-order': 'Organizatorul plătește comanda',
      'organizer-pay-the-order-info': 'Organizatorul plătește comanda cu cardul sau cash la livrare.',
      'info-group-order-required-fields':
        'Câmpurile marcate cu <span style="color:#66BF81">*</span>  sunt obligatorii.',
      'join-group-order-login-info':
        'Pentru a participa la o comandă de grup, alege una din metodele de autentificare.',
      'join-group-order-withou-login': 'Alăturăte comenzii de grup fără să te autentifici',
      'join-group-order-without-login-info':
        'Pentru a participa la o comandă de grup, fără să fii autentificat, te rugăm să introduci numele tău.',
      'join-success': 'Te-ai alăturat comenzii de grup cu succes.',
      'link-copied': 'Link-ul comenzii a fost copiat',
      'wait-for-organizer-to-place-order': 'Se așteaptă finalizarea comenzii de către organizator',
      'thank-you-for-your-payment': 'Îți mulțumim pentru efectuarea plății',
      'thank-you-for-confirming-the-order': 'Îți mulțumim pentru confirmarea comenzii',
      'are-you-sure-delete-order': 'Ești sigur că dorești să ștergi această comandă de grup?',
      'giveup-on-order': 'Renunță la comanda de grup',
      'are-you-sure-giveup-order': 'Ești sigur că dorești să renunți la comanda de grup?',
      'not-allowed-because-already-started':
        'Această acțiune nu este permisă deoarece comanda de grup este deja în desfășurare.',
      'payment-made': 'Plată efectuată',
      'panding-payment': 'Plată în așteptare &nbsp',
      'payment-by-organizer': 'Plată efectuată de organizator',
      participants: 'Participanți',
      'delete-participant': 'Șterge participant',
      'are-you-sure-delete-participant': 'Ești sigur că dorești să ștergi acest participant din comanda de grup?',
      'my-total': 'Totalul meu',
      'paid-total': 'Totalul plătit',
      'total-group-order': 'Total comandă de grup',
      'pay-for-participant': 'Plătește pentru participant',
      'all-participants-must-confirm':
        'Toți participanți la comanda de grup trebuie să își confirme comanda pentru ca aceasta să poată fi finalizată. Puteți să alegeți sa plătiți pentru ceilalți participanți sau să îi scoateți din comanda de grup.',
      'order-confirmed': 'Comandă confirmată',
      'pending-order-confirmation': 'Confirmare comandă în așteptare &nbsp'
    },
    'money-will-be-refunded': 'Suma plătită va fi restituită în contul tău în următoarele zile.',
    'delete-product': 'Șterge produs',
    'are-you-sure-remove-product': 'Sunteți sigur că doriți să scoateți produsul <b>{productName}</b> de pe comandă?',
    'loyalty-at-checkout': 'Aplicarea de <b>puncte bonus și vouchere</b> se face la pasul final al comenzii.',
    'loyalty-validation-error': 'Numărul de puncte introdus nu este corect.',
    'delivery-tax': 'Taxa de livrare',
    'packaging-tax': 'Taxa ambalaje',
    total: 'Total',
    'total-with-benefits': 'Total cu beneficii:',
    'total-products': 'Total produse',
    'minimum-order-is': 'Comanda minimă este de {value}. Transportul nu este luat în considerare. ',
    'minimum-order-voucher-is': 'Comanda minimă pentru a putea folosi acest voucher este de {value}.',
    'place-a-new-order': 'Plasează o nouă comandă',
    checkout: {
      'finish-order': 'Finalizează comanda',
      'do-you-want-cutlery': 'Dorești tacâmuri?',
      'when-to-receive-order': 'Când vrei să primești comanda',
      'have-a-promotional-code': 'Ai un cod promotional (voucher)?',
      'only-one-voucher-per-order': 'Voucherele nu se pot cumula. Poți folosi doar un singur voucher pe comandă.',
      'loyalty-explained':
        'Nr. maxim de puncte /comandă este de {points}.<br/>Punctele bonus se aculumează în urma plasări unei comenzi.<br/>1 punct = {one_currency}, afla mai multe detalii în secțiune <a href="/account/benefits">Beneficii</a> din profilul tău.',
      'save-card': 'Salvează cardul în contul tău pentru comenzi viitoare',
      'use-other-card': 'Folosește un alt card',
      'age-restriction': 'Confirm că am vârsta legală pentru a cumpăra aceste produse, având {age} ani sau mai mult'
    },
    'store-change-result-in-price-change':
      'Prețurile produselor s-au modificat odată cu schimbarea locației:<br/>- {products}'
  },
  'order-success': {
    title: 'Îţi mulţumim pentru comandă, {name}!',
    body: 'Produsele vor ajunge la tine în cel mai scurt timp.',
    'body-pickup': 'Produsele vor începe să fie preparate în cel mai scurt timp.',
    'wait-time-delivery': 'Comanda va ajunge în <span style="white-space:nowrap">~{minutes} minute</span>.',
    'wait-time-pickup': 'Comanda va fi gata în <span style="white-space:nowrap">~{minutes} minute</span>.',
    'later-body-delivery': 'Comanda dvs. va fi livrată la adresa dvs. la {time}.',
    'later-body-pickup': 'Comanda dvs. poate fi preluată la {time} din locația {store_name}.',
    'see-order-status-on-orders-page':
      'Urmărește statusul comenzii din secțiunea <a href="/account/orders">Comenzi</a>',
    'order-brought-to-you':
      'Această comandă îți va aduce <span class="color-green"><b>{points} puncte bonus</b></span>, poți afla mai multe detalii din secțiune <a href="/account/benefits">Beneficii</a>',
    'order-will-bring-you':
      'Această comandă îți aduce <span class="color-green"><b>{points} puncte bonus</b></span>, pe care le poți transforma în reduceri la următoarea comandă, <b>1 punct = {one_currency}</b>.'
  },
  auth: {
    'password-strength': 'Complexitate parolă',
    'password-strength-weak': 'Slabă',
    'password-strength-medium': 'Medie',
    'password-strength-good': 'Bună',
    'password-strength-strong': 'Puternică',
    'password-strength-very-strong': 'Foarte puternică',
    'enter-account': 'Intră în cont',
    'login-for-ordering': 'Pentru a plasa o comandă, alege una din metodele de autentificare.',
    'reset-password': 'Resetează parola',
    'reset-password-info': 'Te rugăm să introduci adresa de email pentru a reseta parola',
    'new-password': 'Setează parolă nouă',
    'forgot-password': 'Ai uitat parola?',
    'validate-phone-number': 'Validează numărul de telefon',
    'validate-phone-enter-code': 'Introdu codul de 4 cifre primit prin SMS:',
    'validate-phone-number-resend-text': 'Retrimite SMS-ul',
    register: 'Creează cont',
    'login-with-google-unavailable': 'Autentificarea cu Google nu este disponibila, momentan.',
    'order-wihout-account': 'Comandă fără cont',
    'place-an-order': 'Plasează o comandă',
    'terms-and-conditions': 'Termeni si condiții',
    'continue-accept-tos': 'Continuând sunteți de acord cu termenii și condițiile de utilizare.',
    'accept-promotion': 'Sunt de acord să primesc promoții personalizate.',
    'i-accept-promotion': 'Vreau să primesc oferte personalizate.',
    'i-agree-with': 'Sunt de acord cu',
    tos: 'termenii și condițiile de utilizare',
    logout: 'Deconectare',
    'logout-success': 'Te-ai deconectat cu succes din cont.',
    'are-you-sure-logout': 'Ești sigur că dorești să te deconectezi?'
  },
  common: {
    'email-saved-success': 'Adresa de e-mail a fost salvată cu succes.',
    'invalid-email': 'Adresa de e-mail este invalidă',
    'delivery-method': 'Metoda de livrare',
    'delivery-address': 'Adresa de livrare',
    'pickup-address': 'Adresa de ridicare',
    'payment-method': 'Metoda de plată',
    'contact-informations': 'Date de contact',
    yes: 'Da',
    no: 'Nu',
    order: 'Comanda',
    'my-order': 'Comanda mea',
    'payment-status': 'Status plată',
    'want-more': 'Dorești încă ceva?',
    'add-to-order': 'Adaugă la comandă',
    'select-language': 'Selectează limba',
    search: 'Caută...',
    from: 'de la',
    to: 'la',
    delete: 'Șterge'
  },
  loyalty: {
    discounts: 'Reduceri',
    'this-order-brings-you':
      'Această comandă îți aduce <span class="color-green"><b>{points} puncte bonus</b></span> pe care le poți transforma în reduceri la următoarea comandă, <b>1 punct = {one_currency}</b>.',
    'create-account-for-loyalty':
      'Creează cont pentru a putea beneficia de punctele bonus, vouchere și oferte speciale.',
    'available-bonus-points': 'Puncte bonus disponibile',
    'congrats-your-total-points': '<b>Felicitari!</b> Totalul de puncte bonus este:',
    vouchers: 'Vouchere',
    'available-vouchers': 'Vouchere disponibile',
    'no-available-vouchers': 'Nu ai vouchere disponibile.',
    'vouchers-info':
      'Voucherele nu se pot cumula. Poți folosi doar un singur voucher pe comandă.<br/>Vouchere nu pot fi transmise la alți utilizatori.<br/>Poți primi vouchere în urma unor promoții speciale.<br/>Voucherele au termen de valabilitate.',
    'bonus-points-info':
      'Punctele bonus se aculumează în urma plasări unei comenzi.<br/><b>1 punct = {one_currency}</b>, poti folosi punctele bonus pentru a obtine reduceri la comenzi.<br/>Punctele bonus nu au termen de valabilitate.<br/>Punctele bonus se pot folosi impreuna cu alte beneficii (ex. vouchere, promotii)',
    'no-bonus-points': 'Momentan nu ai puncte bonus'
  },
  'table-ordering': {
    title: 'Comanda la masă',
    'table-number': 'Masa nr. {number}',
    'send-order-to-waiter': 'Trimite comanda către ospătar',
    'any-time-you-can-ask-for-waiter': 'În orice moment poți solicita ajutorul unui ospătar.',
    'what-qr-can-do-info-ordering':
      'Prin intermediul QR codului poți vizualiza meniu, plasa o comandă și la final îl poti scana pentru a plăti nota de plată.',
    'what-qr-can-do-info-call-waiter':
      'Prin intermediul QR codului poți vizualiza meniu, chema ospătarul și la final poți cere nota de plată.',
    'order-received': 'Comanda a fost preluată',
    bill: 'Nota de plată',
    'call-waiter': 'Cheamă ospătarul',
    'ask-for-bill': 'Cere nota de plată'
  },
  buttons: {
    confirm: 'Confirmă',
    continue: 'Continuă',
    save: 'Salvează',
    'save-address': 'Salvează adresa',
    send: 'Trimite',
    'place-order': 'Plasează comanda',
    'confirm-order': 'Confirmă comanda',
    cancel: 'Renunță',
    'continue-shopping': 'Continuă cumpărăturile',
    validate: 'Validează',
    use: 'Folosește',
    close: 'Închide',
    'pay-with-cc': 'Plătește online cu cardul',
    'change-payment': 'Schimbă modalitatea de plată',
    'back-to-menu': 'Înapoi la meniu'
  },
  fields: {
    name: 'Nume',
    'your-name': 'Numele tău',
    email: 'Email',
    'email-address': 'Adresa de email',
    phone: 'Telefon',
    password: 'Parola',
    'old-password': 'Parola veche',
    'new-password': 'Parola nouă',
    date: 'Data',
    number: 'Număr',
    total: 'Total',
    'pickup-by-car': 'Ridicare din mașină',
    'pickup-counter': 'Ridicare de la recepție',
    'pickup-now': 'Ridică în {minutes} min',
    'pickup-later': 'Ridică mai târziu',
    'delivery-now': 'Livrează cât mai repede (~{minutes} min)',
    'delivery-later': 'Livrează mai târziu',
    size: 'Mărime',
    voucher: 'Voucher',
    points: 'puncte',
    day: 'Ziua',
    hour: 'Ora',
    'bonus-points': 'Puncte bonus',
    'your-message': 'Mesajul tău',
    'no-of-persons': 'Numărul de persoane',
    event: 'Eveniment'
  }
}
