export default {
  homepage: {
    'what-do-you-want-to-eat': 'What do you want to eat?',
    'choose-delivery-type-let-discover':
      'Choose the delivery method and let’s discover all the products that are just a click away.'
  },
  products: {
    unavailable: 'Out of stock'
  },
  axios: {
    403: 'You do not have access to this section. Try logging out and logging back in.',
    524: 'The command may have been executed. Please check this and if not try again.',
    'has-unkown-error': 'Data processing error.',
    'no-internet': 'Please check your internet connection.',
    'unknown-error': 'Please contact us.',
    'message-not-sent': 'The message was not sent.'
  },
  'something-went-wrong': 'Something went wrong.',
  product: {
    description: 'Description',
    allergens: 'Allergens',
    'nutritional-facts': 'Nutrition facts',
    ingredients: 'Ingredientes'
  },
  'payment-methods': {
    cash: 'Cash payment',
    'cash-pickup': 'Cash payment on pickup',
    pos: 'Payment by card at the POS',
    'pos-pickup': 'Card payment at the POS (on pickup)',
    card: 'Online card payment',
    paypal: 'Pay online with PayPal',
    'pay-with': 'Pay with',
    by_organizer: 'Payment made by the organizer',
    words: {
      cash: 'CASH',
      card: 'CARD'
    }
  },
  paymentStatus: {
    succeeded: 'Payment made successfully!',
    processing: "Payment processing. We'll update you when payment is received.",
    'requires-payment-method': 'Payment failed. Please try another payment method.',
    'error-checking':
      'The payment could not be verified. Please check your bank account if the money has been withdrawn.'
  },
  validation: {
    'insert-full-name': 'Please enter your full name.'
  },
  delivery: 'Delivery',
  pickup: 'Pickup',
  categories: 'Categories',
  'your-last-order': 'Your last order',
  'recommended-products': 'Recommended products',
  'all-products': 'All products',
  'search-product': 'Search product...',
  'no-search-result': '0 results for: {search}',
  cookies: {
    welcome: 'Welcome!',
    'info-message': 'We want to give you a personalized experience',
    'info-message-2': 'This site uses cookies to provide you with a personalized experience',
    settings: 'Cookie settings',
    'i-agree': 'I agree',
    'settings-dialog': {
      title: 'This site uses cookies',
      info: 'We use cookies that are necessary for the platform to work.<br>We would also like to set optional analysis cookies in order to be able to optimize the functionality of the site and to analyze and improve the product.<br>We will not set analytics cookies unless you enable them. For more information about the cookies we use, see <a href="#" class="text-positive">Cookies Policy</a>.'
    },
    needed: 'Necessary',
    performance: 'Performance',
    statistically: 'Statistics',
    marketing: 'Marketing'
  },
  contact: {
    title: 'Contact'
  },
  reservations: {
    title: 'Reservations',
    'over-phone': 'For telephone reservations',
    'send-success': 'The form has been sent successfully',
    'you-will-be-contacted': 'You will be contacted by a representative in the shortest time.'
  },
  account: {
    title: 'My account',
    details: 'Account details',
    benefits: 'Benefits',
    'delete-account': 'Delete your account',
    'no-longer-access-to-account': 'You will no longer have access to your benefits and special offers.',
    'are-you-sure-delete-account': 'Are you sure you want to delete this account?',
    'delete-account-lose-points':
      'You will lose <b>{points} {currency}</b> in bonus points, and <b>you will no longer be able to use the vouchers</b>.',
    'delete-account-reason': 'The reason you want to delete your account',
    'delete-delivery-address': 'Delete the delivery address',
    'are-you-sure-delete-delivery-address': 'Are you sure you want to delete the address: {name}?',
    'delete-credit-card': 'Delete the card',
    'are-you-sure-delete-credit-card': 'Are you sure you want to delete the card?: {last4}?',
    'add-new-card': 'You can add a new card, only when you place a new order.',
    orders: 'Orders',
    profile: 'Profile',
    logout: 'Logout',
    'current-orders': 'Current orders',
    'past-orders': 'Order history',
    'update-profile': 'Update profile',
    'change-password': 'Change password',
    'bonus-points': 'Bonus points',
    loyalty: {
      'win-extra-points': 'Earn extra points',
      'explain-order-percent': 'You get {percent}% of what you pay back.',
      'explain-order-delivery-percent': 'You receive back {percent}% of the value of home delivery orders.',
      'explain-order-pickup-percent': 'You receive back {percent}% of the value of pickup orders from your location.',
      'explain-order-table-percent': 'You receive back {percent}% of the value of your table orders.',
      'recommend-to-friend': 'Recommend to a friend',
      'referral-explain': "You receive <b> {points} points </b> on a friend's first order."
    }
  },
  menu: 'Menu',
  'about-us': {
    title: 'About us'
  },
  jobs: {
    title: 'Jobs',
    'choose-the-right-job-for-you': 'Choose the right job for you',
    'apply-for-job': 'Apply for this job',
    'upload-cv': 'Upload CV'
  },
  events: {
    title: 'Events',
    'free-entrance': 'Free entry',
    'current-events': 'Current events',
    'past-events': 'Past events',
    'see-location': 'See location',
    'send-reservation': 'Submit a reservation for the event',
    'no-events': 'There are currently no events',
    'follow-us': 'Please follow us on one of the social networks to find out when we organize the next event.'
  },
  legal: {
    title: 'Legal',
    'terms-and-conditions': 'Terms and conditions',
    'cookie-policy': 'Cookies policy',
    'privacy-policy': 'Privacy policy'
  },
  filters: {
    'select-allergens': 'Select allergens to avoid',
    'info-allergens': 'All categories also include derivative products',
    allergens: {
      1: 'Gluten',
      2: 'Dairy products',
      3: 'Shellfish',
      4: 'Eggs',
      5: 'Fish',
      6: 'Peanuts',
      7: 'Soybean',
      8: 'Nuts',
      9: 'Celery',
      10: 'Mustard',
      11: 'Sesame seeds',
      12: 'Sulphur dioxide',
      13: 'Lupine',
      14: 'Molluscs'
    },
    reset: 'Reset filters'
  },
  geolocation: {
    'choose-other-pickup': 'Choose another pickup address',
    'choose-other-delivery': 'Choose another delivery address',
    'see-location-details': 'See locations details',
    'warning-delivery': 'Please note, some products and discounts are not available for delivery.',
    'street-number': 'Street, number',
    'apartment-floor': 'Floor, apartment, intercom',
    'location-name': 'Location name ex: Home (optional)',
    'special-informations': 'Special instructions (optional)',
    'no-address': 'You must enter an address.',
    'no-delivery-to-this-location': 'Unfortunately we do not deliver to this address.',
    'leave-email-notify-delivery-area':
      'Leave us your e-mail address and we will let you know when we will deliver to this address.',
    'no-delivery-to-this-location-has-pickup': 'Unfortunately we do not deliver to this address.',
    'address-saved-success': 'Address saved successfully.',
    'location-saved-success': 'Location saved successfully.',
    'add-address': 'Add new address',
    'edit-address': 'Edit address',
    'save-address': 'Save the address',
    'select-address-on-the-map': 'Locate your address on the map',
    'no-results':
      'No address found.<br/>Try searching for the city or other important point near your location and then you can move the marker exactly to your location.'
  },
  'order-product': {
    'special-instructions': 'Special instructions',
    'instructions-extra-charge': 'Some modifications could have an extra charge',
    'add-product-to-order': 'Add to order',
    maxim: 'Maximum',
    options: 'options',
    'product-added-success': 'The product was added successfully.',
    'product-delete-success': 'The product has been successfully deleted.',
    'max-options': 'The maximum number of options that can be selected is {options}.',
    'max-quantity': 'The maximum quantity that can be ordered is {quantity}.',
    'min-options-notification': 'The minimum number of options that can be selected for {name} is {options}.',
    'min-quantity-notification': 'The minimum quantity that can be ordered for {name} is {quantity}.',
    'choose-option': 'Choose the option',
    'already-added-order-now':
      'You have products in your basket for delivery today. The order with delivery today cannot be combined with an order with delivery on a scheduled date.'
  },
  cart: {
    'your-cart-is-empty': 'Your cart is empty',
    'no-products': 'You currently have no products in your cart',
    'please-add-product': 'Please add products to the cart to be able to place an order.',
    'what-do-you-want-to-eat': 'What do you want to eat?',
    'group-order': {
      or: '- or -',
      'initiate-group-order': 'Initiate a </br> group order',
      title: 'Group order',
      'payment-method': 'How do you want the payment to be made?',
      'info-link':
        'Send this link to the people you want to invite to the group order, you can always delete participants.',
      'copy-link': 'Copy order link',
      'share-link': 'Share order link',
      'open-order': 'Open group order',
      'split-order': 'Split the note',
      'split-order-info': 'Everyone pays with their personal card',
      'integral-order': 'I pay the whole order',
      'integral-order-info': 'Pay with card or cash on delivery',
      'delete-order': 'Delete group order',
      'info-group-order':
        'You can place a group order without having products in your cart. Do this from the "Participants" section.',
      'join-group-order-created-by': 'Join the group order created by {name}.',
      'join-group-order': 'Join the group order',
      'organizer-pay-the-order': 'Organizer pays the order',
      'organizer-pay-the-order-info': 'Organizer pays the order with card or cash on delivery.',
      'info-group-order-required-fields': 'Fields marked with <span style="color:#66BF81">*</span> are mandatory.',
      'join-group-order-login-info': 'To participate in a group order, choose one of the authentication methods.',
      'join-group-order-withou-login': 'Join the group order without logging in',
      'join-group-order-without-login-info':
        'To participate in a group order, without being logged in, please enter your name.',
      'join-success': 'You have successfully joined the group order.',
      'link-copied': 'The order link has been copied',
      'wait-for-organizer-to-place-order': 'Waiting for the organizer to complete the order',
      'thank-you-for-your-payment': 'Thank you for your payment',
      'thank-you-for-confirming-the-order': 'Thank you for confirming the order',
      'are-you-sure-delete-order': 'Are you sure you want to delete this group order?',
      'giveup-on-order': 'Give up on group order',
      'are-you-sure-giveup-order': 'Are you sure you want to give up on this group order?',
      'not-allowed-because-already-started':
        'This action is not allowed because the group order is already in progress.',
      'payment-made': 'Payment made',
      'panding-payment': 'Payment pending &nbsp',
      'payment-by-organizer': 'Payment made by organizer',
      participants: 'Participants',
      'delete-participant': 'Delete participant',
      'are-you-sure-delete-participant': 'Are you sure you want to delete this participant from the group order?',
      'my-total': 'My total',
      'paid-total': 'Paid total',
      'total-group-order': 'Total group order',
      'pay-for-participant': 'Pay for participant',
      'all-participants-must-confirm':
        'All participants in the group order must confirm their order for it to be completed. You can choose to pay for the other participants or to remove them from the group order.',
      'all-participants-must-confirm-total':
        'All participants in the group order must confirm their order for it to be completed. You can remove them from the group order.',
      'order-confirmed': 'Order confirmed',
      'pending-order-confirmation': 'Pending order confirmation &nbsp'
    },
    'money-will-be-refunded': 'The amount paid will be returned to your account in the following days.',
    'delete-product': 'Delete product',
    'are-you-sure-remove-product': 'Are you sure you want to remove the product <b>{productName}</b> from the order?',
    'loyalty-at-checkout':
      'The application of <b>bonus points and vouchers</b> is done at the final step of the order.',
    'loyalty-validation-error': 'The number of points entered is incorrect.',
    'delivery-tax': 'Delivery costs',
    'packaging-tax': 'Packaging fee',
    total: 'Total',
    'total-with-benefits': 'Total with benefits:',
    'total-products': 'Total products',
    'minimum-order-is': 'The minimum order is of {value}. Shipping is not included.',
    'minimum-order-voucher-is': 'The minimum order to use this voucher is {value}.',
    'place-a-new-order': 'Place a new order',
    checkout: {
      'finish-order': 'Complete the order',
      'do-you-want-cutlery': 'Do you want cutlery?',
      'when-to-receive-order': 'When do you want to receive the order?',
      'have-a-promotional-code': 'Do you have a promo code?',
      'only-one-voucher-per-order': 'Vouchers cannot be combined. You can only use one voucher per order.',
      'loyalty-explained':
        'No. maximum points/order is of {points}.<br/>Bonus points are accumulated after placing an order.<br/>1 point = {one_currency}, find out more details in the <a href="/account/benefits">Benefits </a> section of your profile.',
      'save-card': 'Save the card to your account for future orders',
      'use-other-card': 'Use another card',
      'age-restriction':
        'I confirm that I am of legal age to purchase these products, being {age} years of age or older'
    },
    'store-change-result-in-price-change': 'Product prices have changed with the location change: <br/>- {products}'
  },
  'order-success': {
    title: 'Thank you for your order.',
    body: 'It will be delivered as soon as posible',
    'body-pickup': 'The products will start to be prepared as soon as possible.',
    'wait-time-delivery':
      'Your order will arrrive at you in <span style="white-space:nowrap">~{minutes} minutes</span>.',
    'wait-time-pickup': 'Your order will be ready in <span style="white-space:nowrap">~{minutes} minutes</span>.',
    'later-body-delivery': 'Your order will be delivered to your home at {time}.',
    'later-body-pickup': 'Your order can be picked up at {time} from location: {store_name}.',
    'see-order-status-on-orders-page': 'Track your order status in the <a href="/account/orders">Orders</a> section',
    'order-brought-to-you':
      'This order will bring you <span class="color-green"><b>{points} bonus points</b></span>, you can find out more details in the <a href="/account/benefits">Benefits</a> section',
    'order-will-bring-you':
      'This order earns you <span class="color-green"><b>{points} bonus points</b></span>, which you can turn into discounts on your next order, <b>1 point = {one_currency}</b>.'
  },
  common: {
    'email-saved-success': 'The email address was saved successfully.',
    'invalid-email': 'Your email address is invalid',
    'delivery-method': 'Delivery Method',
    'delivery-address': 'Delivery address',
    'pickup-address': 'Pickup address',
    'payment-method': 'Payment method',
    'contact-informations': 'Contact information',
    yes: 'Yes',
    no: 'No',
    order: 'Order',
    'my-order': 'My order',
    'continue-with': 'Continue with',
    or: 'or',
    'link-copied': 'The link was successfully copied.',
    'link-couldnt-be-copied': 'The link could not be copied.',
    'payment-status': 'Payment status',
    'want-more': 'Do you want anything else?',
    'add-to-order': 'Add to order',
    'select-language': 'Choose your language',
    search: 'Search...',
    from: 'from',
    to: 'to',
    delete: 'Delete'
  },
  loyalty: {
    discounts: 'Discounts',
    'this-order-brings-you':
      'This order earns <span class="color-green"><b>{points} bonus points</b></span> which you can turn into discounts on your next order, <b>1 point = {one_currency}</b>.',
    'create-account-for-loyalty': 'Create an account to benefit from bonus points, vouchers and special offers.',
    'available-bonus-points': 'Bonus points available',
    'congrats-your-total-points': '<b>Congratulations!</b> The total bonus points is:',
    vouchers: 'Voucheres',
    'available-vouchers': 'Vouchers available',
    'no-available-vouchers': 'You have no vouchers available.',
    'vouchers-info':
      'Vouchers cannot be combined. You can only use one voucher per order.<br/>Vouchers cannot be transferred to other users.<br/>You can receive vouchers following special promotions.<br/>Vouchers have a validity period.',
    'bonus-points-info':
      'Bonus points are accumulated after placing an order.<br/><b>1 point = {one_currency}</b>, you can use bonus points to get discounts on orders.<br/>Bonus points do not expire.<br/>Bonus points can be used together with other benefits (e.g. vouchers, promotions)',
    'no-bonus-points': 'You currently have no bonus points'
  },
  'table-ordering': {
    title: 'Table ordering',
    'table-number': 'Table no. {number}',
    'send-order-to-waiter': 'Send the order to the waiter',
    'any-time-you-can-ask-for-waiter': 'At any time you can request the help of a waiter.',
    'what-qr-can-do-info-ordering':
      'Through the QR code you can view the menu, place an order and finally scan it to pay the bill.',
    'what-qr-can-do-info-call-waiter':
      'Through the QR code you can view the menu, call the waiter and at the end you can ask for the bill.',
    'order-received': 'The order has been taken',
    bill: 'Pay the order',
    'call-waiter': 'Call the waiter',
    'ask-for-bill': 'Ask for the bill'
  },
  buttons: {
    confirm: 'Confirm',
    continue: 'Continue',
    save: 'Save',
    'save-address': 'Save the address',
    send: 'Send',
    'place-order': 'Place the order',
    'confirm-order': 'Confirm the order',
    cancel: 'Cancel',
    'continue-shopping': 'Continue shopping',
    validate: 'Validate',
    use: 'use',
    close: 'Close',
    'pay-with-cc': 'Pay online with your card',
    'change-payment': 'Change payment method',
    menu: 'Menu',
    pay: 'Pay',
    'copy-link': 'Copy link',
    'back-to-menu': 'Back to the menu'
  },
  fields: {
    name: 'Name',
    'your-name': 'Your name',
    'email-address': 'Email address',
    email: 'Email',
    phone: 'Phone',
    password: 'Password',
    'old-password': 'Old password',
    'new-password': 'New password',
    date: 'Date',
    number: 'Number',
    total: 'Total',
    'pickup-by-car': 'Pickup by car',
    'pickup-counter': 'Pickup at the counter',
    'pickup-now': 'Pickup in {minutes} min.',
    'pickup-later': 'Pickup later',
    'delivery-now': 'Deliver now ({minutes} min.)',
    'delivery-later': 'Deliver later',
    size: 'Size',
    voucher: 'Voucher',
    points: 'point',
    day: 'Day',
    hour: 'Hour',
    'bonus-points': 'Bonus points',
    'your-message': 'Your message',
    'no-of-persons': 'The number of people',
    event: 'Event'
  },
  'no-products': 'There are no products in this category.',
  'shop-closed': 'No deliveries are made at this time.',
  'privacy-policy': 'Privacy policy',
  'cookie-policy': 'Cookie policy',
  'terms-and-conditions': 'Terms and conditions',
  order: 'Your order',
  'order-now': 'Order now',
  'finish-order': 'Complete the order',
  'view-your-cart': 'View your cart',
  'send-order': 'Send order',
  'total-order': 'Total order',
  quantity: 'Quantity',
  'special-instructions': 'Special instructions',
  'instructions-extra-charge': 'Some modifications could have an extra charge',
  'add-product-to-order': 'Add to order',
  maxim: 'Maximum',
  options: 'options',
  'product-added-success': 'The product was added successfully.',
  'max-options': 'The maximum number of options that can be selected is {options}.',
  'max-quantity': 'The maximum quantity that can be ordered is {quantity}.',
  'min-options-notification': 'The minimum number of options that can be selected for {name} is {options}.',
  'min-quantity-notification': 'The minimum quantity that can be ordered for {name} is {quantity}.',
  'powered-by': 'Powered by ',
  'product-name': 'Product name',
  'unit-price': 'Unit price',
  'total-price': 'Total price',
  'your-name': 'Your name',
  'your-phone': 'Your phone',
  address: 'Address',
  'i-agree-with': 'I agree with',
  tos: 'the terms and conditions',
  'continue-accept-tos': 'By continuing you agree to the terms and conditions of use.',
  'auto-accept-promotion': 'I agree to receive personalized promotions.',
  'view-cart': 'See the shopping cart',
  'payment-cash': 'I pay cash on delivery',
  'payment-pos': 'I pay with the card on delivery',
  'payment-cash-pickup': 'I pay cash on pickup',
  'payment-pos-pickup': 'I pay with the card on pickup',
  'payment-card': 'I pay now with credit card',
  'payment-paypal': 'I pay now with PayPal',
  'min-order-value': 'The minimum order is {value}.',
  'voucher-code': 'Voucher code',
  'validate-voucher': 'Validate',
  subtotal: 'Subtotal',
  total: 'Total',
  voucher: 'Voucher',
  'delivery-address': 'Delivery address',
  'street-name-number': 'Street name and number',
  'enterence-floor-apartment': 'Block, entrance, apartment',
  'save-address': 'Save address',
  'delete-product': 'Remove product',
  'are-you-sure-remove-product': 'Are you sure do you want to remove <b>{productName}</b> from the order?',
  cancel: 'Cancel',
  'product-delete-success': 'The product was removed successfully.',
  'i-want-contactless-delivery': 'I want contactless delivery.',
  'i-want-cutlery': 'I want cutlery',
  'i-accept-promotion': 'Yes, I would like to receive special promotions',
  'address-saved-success': 'The address was saved successfully.',
  'address-must-from-list': 'You must select the address from the list.',
  'marker-out-of-range': 'You moved the cursor too far from the written address.',
  'choose-address-hint': 'Enter the street name directly followed by the address number, for example: Main Street 21',
  'change-address': 'Change address',
  'set-address': 'Set the delivery address',
  'set-street-number': 'Set street name and number',
  transport: 'Transport',
  'free-transport-after': 'Free delivery after',
  'delivery-time': 'Delivery time',
  'close-dialog': 'Close the window',
  'share-product': 'Share product',
  'copy-link': 'Copy',
  'copy-link-success': 'The link was successfully copied.',
  'copy-link-error': 'The link could not be copied. Please select and copy it manually.',
  'pay-order': 'Pay the order',
  'pay-now': 'I pay securely',
  'go-homepage': 'Go to the homepage',
  change: 'Change',
  gdpr: {
    'this-web-use-cookies': 'This website uses cookies',
    text: 'This website stores data such as cookies to enable important site functionality including analytics, targeting, and personalization. To learn more, you can read our <a href="/page/cookie-policy" style="color:#fff">Privacy policy here</a>.',
    'i-understand': 'I understand'
  },
  'gmap-autocompelte-no-results': 'No results.',
  'delivery-type': {
    delivery: 'Delivery',
    pickup: 'Pickup'
  },
  'set-pickup': 'Set store',
  'change-pickup': 'Change store',
  'pickup-saved-success': 'The store has been saved successfully.',
  auth: {
    'password-strength': 'Password strength',
    'password-strength-weak': 'Weak',
    'password-strength-medium': 'Medium',
    'password-strength-good': 'Good',
    'password-strength-strong': 'Strong',
    'password-strength-very-strong': 'Very strong',
    'enter-account': 'Login into account',
    'login-for-ordering': 'To place an order, choose one of the authentication methods.',
    'reset-password': 'Reset password',
    'reset-password-info': 'Please enter your email address to reset your password',
    'new-password': 'Set new password',
    'forgot-password': 'Have you forgotten the password?',
    'validate-phone-number': 'Validate the phone number',
    'validate-phone-enter-code': 'Enter the 4-digit code received by SMS:',
    'validate-phone-number-resend-text': 'Resend the SMS',
    'login-with-google-unavailable': 'Google authentication is not available at the moment.',
    'order-wihout-account': 'Order without account',
    'place-an-order': 'Place an order',
    'terms-and-conditions': 'Terms and conditions',
    'accept-promotion': 'I agree to receive personalized promotions.',
    'i-accept-promotion': 'I want to receive personalized offers.',
    'i-agree-with': 'I agree with',
    tos: 'terms and conditions of use',
    logout: 'Log Out',
    'logout-success': 'You have successfully logged out of your account.',
    'are-you-sure-logout': 'Are you sure you want to log out?',
    register: 'Create an account',
    'register-btn': 'Create account',
    login: 'Welcome back',
    'login-btn': 'Login',
    'login-into-account': 'Login into account',
    'reset-password-btn': 'Reset password',
    'new-password-btn': 'Save password'
  },
  'validate-phone-number': {
    'resend-text': "Didn't receive SMS?",
    'resend-button': 'Resend SMS'
  },
  unsubscribe: 'You’ve been unsubscribed',
  'unsubscribe-text': 'We’re really sorry to see you go, but you’re now unsubscribed from our email list.',
  'or-picked-up-at': 'or picked up at',
  packs: 'Packing'
}
